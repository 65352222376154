<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-wrap">

      <b-col cols="12" md="3">

        <div class="wameed-input text-reg-14" style="margin-bottom: 1.4rem;">
          <label class="text-font-main text-reg-14 "
                 style="margin-bottom: 8px;">{{ $t('discounts.section.condition.discount.title') }}</label>
          <treeselect
              v-model="form.key"
              :multiple="false"
              :required="true"
              :options="filteredConditions"
              :limit="1"
              :clearable="false"
              :searchable="false"
              :loadingText="$t('common.loadingText')"
              :noChildrenText="$t('common.noChildrenText')"
              :noOptionsText="$t('common.noOptionsText')"
              :noResultsText="$t('common.noResultsText')"
              :disabled="isUpdate"
              @input="changeCondition"
              @select="reset"
              :placeholder="$t('discounts.discounts.placeholder')"
          >
            <!--                          <div slot="before-list" >all</div>-->
          </treeselect>
        </div>
      </b-col>


      <template v-if="selectedCondition!=null">

        <b-col cols="12" md="3" v-if="selectedCondition.input==='dropdown' && !selectedCondition.conditionValue">
          <div class="wameed-input text-reg-14">
            <label class="text-font-main text-reg-14 "
                   style="margin-bottom: 8px;">{{ selectedCondition.conditions.title }}</label>
            <treeselect
                v-model="form.value"
                :multiple="selectedCondition.multiple"
                :required="true"
                :options="selectedCondition.conditions.children"
                :limit="1"
                :loadingText="$t('common.loadingText')"
                :noChildrenText="$t('common.noChildrenText')"
                :noOptionsText="$t('common.noOptionsText')"
                :noResultsText="$t('common.noResultsText')"
                :auto-load-root-options="true"
                :default-expand-level="1"
                @input="change"
                :placeholder="$t('discounts.discounts.select_option')"
            />
          </div>
        </b-col>

        <template v-if="selectedCondition.conditionValue">
          <b-col cols="12" md="3" v-if="selectedCondition.input==='dropdown'">
            <div class="wameed-input text-reg-14">
              <label class="text-font-main text-reg-14 "
                     style="margin-bottom: 8px;">{{ selectedCondition.conditions.title }}</label>
              <treeselect
                  v-model="form.condition"
                  :multiple="false"
                  :required="true"
                  :options="conditions"
                  :limit="1"
                  :clearable="false"
                  :searchable="false"
                  :loadingText="$t('common.loadingText')"
                  :noChildrenText="$t('common.noChildrenText')"
                  :noOptionsText="$t('common.noOptionsText')"
                  :noResultsText="$t('common.noResultsText')"
                  :disabled="isUpdate"
                  @input="change"
                  :placeholder="$t('discounts.discounts.select_option')"
              >
                <!--                          <div slot="before-list" >all</div>-->
              </treeselect>
            </div>
          </b-col>

          <b-col cols="12" md="3" v-if="selectedCondition.conditionValue.type=='number'">
            <TextInput
                :readonly="isUpdate"
                v-model="form.value"
                rules="required"
                field-classes="w-100"
                :label="selectedCondition.conditionValue.label"
                :placeholder="$t('form.text.placeholder')"
                type="number"
                @input="change"
            />
          </b-col>
        </template>
      </template>
      <b-col cols="12" md="3">
        <div>

          <div class="remove-icon" @click="removeCondition">
            <trash-icon/>
            {{ $t('btn.delete') }}
          </div>
        </div>
      </b-col>
    </div>

  </div>
</template>

<script>
import {WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'
import {filterDiscountCondition, filterDiscountConditionTypes} from "@/enums/discountConditions.enum";

import Treeselect from '@riophae/vue-treeselect'
import {mapGetters} from "vuex";

export default {
  components: {
    TextInput,
    Treeselect
  },
  data() {
    return {
      selectedCondition: null,
      form: {
        key: "0",
        condition: null,
        value: null,
      },
    };
  },
  props: {
    isUpdate: {
      default: false,
    },
    title: {
      default: 'default',
      type: String
    },
    selectedConditions: {
      default: null,
      type: Array
    },
    data: Object,
    value: Object
  },
  watch: {
    value(newVal) {

      if (newVal != null) {
        this.form = newVal;
      } else {
        this.clear()
      }
    },

  },
  computed: {
    ...mapGetters({
      getUsers: 'admin/discounts/getUsers',
      getConditions: 'admin/discounts/getConditions',
    }),

    filteredConditions() {

      // get only the keys in the selected conditions
      let keys = [];
      if (this.selectedConditions)
        keys = this.selectedConditions.map(x => x.key);
      // remove any condition that is contained in the keys
      return this.getConditions.filter(x => !keys.includes(x.id));

    },
    discountConditions() {
      let data = filterDiscountConditionTypes(this.title);
      if (data)
        return data;
      return null;
    },
    conditions() {
      let conditions = filterDiscountCondition(this.form.key);
      if (conditions)
        return conditions.conditions.children;
      return null;
    },


  },
  methods: {

    removeCondition() {
      let key = null;
      if (this.selectedCondition)
        key = this.selectedCondition.id;

      this.clear();

      this.$emit('removeCondition', key);
    },

    reset() {

      this.form.value = null;
      this.form.condition = null;
    },
    changeType(e) {

      this.$emit('input', this.form);
    },
    changeCondition(e) {

      this.selectedCondition = this.getConditions.find(x => x.id === e);

      this.$emit('input', this.form);
    },
    change() {
      this.$emit('input', this.form);
    },

    clear() {
      this.form = {
        key: "0",
        condition: null,
        value: null,
      };
    }
  },
  created() {


    if (this.value != null) {

      this.form = this.value;
      this.selectedCondition = this.getConditions.find(x => x.id === this.value.key);
    }
  },

};
</script>


<style scoped>
.remove-icon {
  background-color: rgba(239, 83, 80, 0.1);
  border: 1px solid #ef5350;
  color: #ef5350;
  border-radius: 8px;
  padding: 6px 8px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  margin-top: 36px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

}

@media (max-width: 767px) {
  .remove-icon {
    margin-top: 0;
  }
}
</style>
